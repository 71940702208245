import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const CardholderAccountSetupViewChunk = React.lazy(() =>
  import('../feature-sets/CardholderAccountSetupView').then(
    ({ CardholderAccountSetupView }) => ({
      default: CardholderAccountSetupView,
    })
  )
)

export const CardholderAccountSetupPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.cardholderAccountSetupPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.cardholderAccountPageTitle),
          link: url('CardholderAccountPage'),
        },
        {
          name: intl.formatMessage(messages.cardholderAccountSetupPageTitle),
        },
      ]}
    >
      <CardholderAccountSetupViewChunk />
    </Page>
  )
}
