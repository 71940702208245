import { defineMessages } from 'react-intl'

const scope = `procurify.spending-cards.page`

export default defineMessages({
  cardholderAccountPageTitle: {
    id: `${scope}.cardholderAccountPageTitle`,
    defaultMessage: 'Cardholder account',
  },
  cardholderAccountSetupPageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Setup cardholder',
  },
  cardholderAccountEditPageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Edit cardholder',
  },
  cardholderAccountVerifyPageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Verify cardholder',
  },
})
