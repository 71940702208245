import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const CardholderAccountVerifyViewChunk = React.lazy(() =>
  import('../feature-sets/CardholderAccountVerifyView').then(
    ({ CardholderAccountVerifyView }) => ({
      default: CardholderAccountVerifyView,
    })
  )
)

export const CardholderAccountVerifyPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.cardholderAccountVerifyPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.cardholderAccountPageTitle),
          link: url('CardholderAccountPage'),
        },
        {
          name: intl.formatMessage(messages.cardholderAccountVerifyPageTitle),
        },
      ]}
    >
      <CardholderAccountVerifyViewChunk />
    </Page>
  )
}
