import * as React from 'react'

import {
  Redirect,
  Route,
  type RouteComponentProps,
  type RouteProps,
  Switch,
} from 'react-router'

import { ProtectedRoute } from '@webapp/app/components'
import { useModuleConfig } from '@webapp/app/config/useModuleConfig'
import { uuidRegExp } from '@webapp/config/react-router'
import { SpendingCardFundListTabs } from '@webapp/containers/SpendingCardManagementDetailsView/types'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { useIsSpendingCardsSetUp } from '@webapp/hooks/useIsSpendingCardsSetUp'
import { PayCardStatuses, PayCardStatusesV2 } from '@webapp/models/enums'
import {
  CardholderAccountEditPage,
  CardholderAccountPage,
  CardholderAccountSetupPage,
  CardholderAccountVerifyPage,
} from '@webapp/modules/spending-cards'
import {
  SpendingCardCreateFundRequestPage,
  SpendingCardRequestVirtualCardPage,
  SpendingCardSetupPage,
  SpendingCardTransactionListPage,
  SpendingCardVirtualCardRequestDetailsPage,
  SpendingCardVirtualCardTopUpDetailsPage,
} from '@webapp/pages'
import {
  SpendingCardApprovalListPage,
  SpendingCardApprovalListPageTabs,
} from '@webapp/pages/SpendingCardApprovalListPage'
import { SpendingCardApproveFundRequestPage } from '@webapp/pages/SpendingCardApproveFundRequestPage'
import { SpendingCardApproveVirtualCardRequestPage } from '@webapp/pages/SpendingCardApproveVirtualCardRequestPage'
import { SpendingCardFundDetailsPage } from '@webapp/pages/SpendingCardFundDetailsPage'
import { SpendingCardFundDetailsPageLegacy } from '@webapp/pages/SpendingCardFundDetailsPageLegacy'
import { SpendingCardFundRequestDetailsPage } from '@webapp/pages/SpendingCardFundRequestDetailsPage'
import { SpendingCardManagementDetailsPage } from '@webapp/pages/SpendingCardManagementDetailsPage'
import { SpendingCardManagementFundDetailsPage } from '@webapp/pages/SpendingCardManagementFundDetailsPage'
import { SpendingCardManagementListPage } from '@webapp/pages/SpendingCardManagementListPage'
import {
  SpendingCardPhysicalCardPage,
  SpendingCardPhysicalCardTabs,
} from '@webapp/pages/SpendingCardPhysicalCardPage'
import { SpendingCardReconcileTransactionsPage } from '@webapp/pages/SpendingCardReconcileTransactionsPage'
import { SPENDING_CARD_SETUP_PAGE } from '@webapp/pages/SpendingCardSetupPage'
import { SpendingCardTransactionListPageTabs } from '@webapp/pages/SpendingCardTransactionListPage'
import { SpendingCardVirtualCardDetailsPage } from '@webapp/pages/SpendingCardVirtualCardDetailsPage'
import {
  SpendingCardVirtualCardsPage,
  SpendingCardVirtualCardsPageTabs,
} from '@webapp/pages/SpendingCardVirtualCardsPage'
import {
  SPENDING_CARD_CARD_URL,
  SPENDING_CARD_MANAGEMENT_URL,
  SPENDING_CARD_RECONCILE_TRANSACTIONS_URL,
} from './constants/urls'

const fundIdFormat =
  /([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})/
    .source
const cardStatusFormat = Object.values(PayCardStatusesV2).join('|')

const RequestFundRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['request__pay_request']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const ApproveRequestRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['approval__pay_request']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const MyCardRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['pay__my_card']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const VirtualCardsRoute = ({ path, component, exact = false }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['pay__virtual_cards']}
      featureSwitches={['stripe_integration']}
      exact={exact}
    />
  )
}

const PhysicalCardRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['pay__my_card']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const CardManagementComponent = ({
  component: Component,
  ...otherProps
}: RouteComponentProps & {
  component: RouteProps['component']
}) => {
  const { isLoading } = useIsSpendingCardsSetUp()

  return isLoading ? null : <Component {...otherProps} />
}

const CardManagementRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      render={(props) => (
        <CardManagementComponent component={component} {...props} />
      )}
      navigationPermissions={['pay__card_management']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const ReconcileTransactionsRoute = ({ path, component }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      navigationPermissions={['pay__reconcile_transactions']}
      featureSwitches={['stripe_integration']}
    />
  )
}

const CardholderRoute = ({ path, component, exact = false }: RouteProps) => {
  return (
    <ProtectedRoute
      path={path}
      component={component}
      customFilter={({ featureSwitches, navigationPermissions }) =>
        navigationPermissions.includes('pay__cardholder_account') &&
        (featureSwitches.enable_airwallex_issuing === true ||
          featureSwitches.enable_airwallex_onboarding)
      }
      exact={exact}
    />
  )
}

export const SpendingCardRouter = () => {
  const { url, brp } = useAppRoutes()

  const {
    routes: { enableSpendingCardRoutes },
  } = useModuleConfig()

  if (!enableSpendingCardRoutes) return <Redirect to={url('DashboardPage')} />

  return (
    <Switch>
      {/* RFF Routes */}
      <RequestFundRoute
        path={`/spending-card/requests/:uuid(${uuidRegExp.source})`}
        component={SpendingCardFundRequestDetailsPage}
      />
      <RequestFundRoute
        path={brp('SpendingCardPhysicalCardPage', {
          tab: `:tab(${SpendingCardPhysicalCardTabs.PENDING}|${SpendingCardPhysicalCardTabs.OPEN}|${SpendingCardPhysicalCardTabs.CLOSED})`,
        })}
        component={SpendingCardPhysicalCardPage}
      />
      <RequestFundRoute
        path={brp('SpendingCardCreateFundRequestPage')}
        component={SpendingCardCreateFundRequestPage}
      />
      <Redirect
        from='/spending-card/requests/create'
        to={url('SpendingCardCreateFundRequestPage')}
      />
      <Redirect
        from='/spending-card/requests/create'
        to={url('SpendingCardPhysicalCardPage', {
          tab: SpendingCardPhysicalCardTabs.PENDING,
        })}
      />
      <Redirect
        path='/spending-card/requests/create'
        to={url('SpendingCardPhysicalCardPage', {
          tab: SpendingCardPhysicalCardTabs.PENDING,
        })}
      />
      {/* Approve Fund Requests Routes */}
      <ApproveRequestRoute
        path={brp('SpendingCardApprovalListPage', {
          tab: `:tab(${SpendingCardApprovalListPageTabs.ALL}|${SpendingCardApprovalListPageTabs.APPROVED}|${SpendingCardApprovalListPageTabs.DENIED}|${SpendingCardApprovalListPageTabs.PENDING})`,
        })}
        component={SpendingCardApprovalListPage}
      />
      <ApproveRequestRoute
        path={url('SpendingCardApproveFundRequestPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardApproveFundRequestPage}
      />
      <ApproveRequestRoute
        path={url('SpendingCardApproveVirtualCardRequestPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardApproveVirtualCardRequestPage}
      />
      <Redirect
        from={brp('SpendingCardApprovalListPage', {
          tab: '',
        })}
        to={url('SpendingCardApprovalListPage', {
          tab: SpendingCardApprovalListPageTabs.PENDING,
        })}
      />
      {/* My Card Routes */}
      <MyCardRoute
        path={brp('SpendingCardPhysicalCardPage', {
          tab: `:tab(${SpendingCardPhysicalCardTabs.PENDING}|${SpendingCardPhysicalCardTabs.OPEN}|${SpendingCardPhysicalCardTabs.CLOSED})`,
        })}
        component={SpendingCardPhysicalCardPage}
      />
      <MyCardRoute
        path={`${SPENDING_CARD_CARD_URL}/fund/:uuid${fundIdFormat}`}
        component={SpendingCardFundDetailsPageLegacy}
      />
      <Redirect
        path={`${SPENDING_CARD_CARD_URL}`}
        to={url('SpendingCardPhysicalCardPage', {
          tab: SpendingCardPhysicalCardTabs.OPEN,
        })}
      />
      {/* Virtual Cards Routes - FT_370_MULTIPLE_CARDS_ON_A_CARDHOLDER */}
      <VirtualCardsRoute
        path={brp('SpendingCardVirtualCardsPage', {
          tab: `:tab(${SpendingCardVirtualCardsPageTabs.ACTIVE}|${SpendingCardVirtualCardsPageTabs.DENIED}|${SpendingCardVirtualCardsPageTabs.PENDING}|${SpendingCardVirtualCardsPageTabs.LOCKED})`,
        })}
        component={SpendingCardVirtualCardsPage}
      />
      <VirtualCardsRoute
        path={brp('SpendingCardRequestVirtualCardPage')}
        component={SpendingCardRequestVirtualCardPage}
        exact
      />
      <VirtualCardsRoute
        path={brp('SpendingCardVirtualCardDetailsPage', {
          uuid: `:uuid`,
        })}
        component={SpendingCardVirtualCardDetailsPage}
        exact
      />
      <VirtualCardsRoute
        path={brp('SpendingCardVirtualCardRequestDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardVirtualCardRequestDetailsPage}
      />
      <VirtualCardsRoute
        path={brp('SpendingCardVirtualCardTopUpDetailsPage', {
          card: `:card`,
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardVirtualCardTopUpDetailsPage}
        exact
      />
      <Redirect
        from={brp('SpendingCardVirtualCardsPage', {
          tab: '',
        })}
        to={url('SpendingCardVirtualCardsPage', {
          tab: SpendingCardVirtualCardsPageTabs.ACTIVE,
        })}
      />
      {/* Physical Card Routes - FT_370_MULTIPLE_CARDS_ON_A_CARDHOLDER */}
      <PhysicalCardRoute
        path={brp('SpendingCardPhysicalCardPage', {
          tab: `:tab(${SpendingCardPhysicalCardTabs.PENDING}|${SpendingCardPhysicalCardTabs.OPEN}|${SpendingCardPhysicalCardTabs.CLOSED})`,
        })}
        component={SpendingCardPhysicalCardPage}
      />
      <PhysicalCardRoute
        path={brp('SpendingCardFundRequestDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardFundRequestDetailsPage}
      />
      <PhysicalCardRoute
        path={brp('SpendingCardFundDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={SpendingCardFundDetailsPage}
      />
      <PhysicalCardRoute
        path={brp('SpendingCardCreateFundRequestPage')}
        component={SpendingCardCreateFundRequestPage}
      />
      <Redirect
        from={brp('SpendingCardPhysicalCardPage', {
          tab: '',
        })}
        to={url('SpendingCardPhysicalCardPage', {
          tab: SpendingCardPhysicalCardTabs.PENDING,
        })}
      />
      {/* My Transactions Route */}
      <ProtectedRoute
        path={brp('SpendingCardTransactionListPage', {
          tab: `:tab(${SpendingCardTransactionListPageTabs.SUCCESSFUL}|${SpendingCardTransactionListPageTabs.DECLINED})`,
        })}
        component={SpendingCardTransactionListPage}
        navigationPermissions={['pay__my_transactions']}
        featureSwitches={['stripe_integration']}
        exact
      />
      <Redirect
        from={brp('SpendingCardTransactionListPage', {
          tab: '',
        })}
        to={url('SpendingCardTransactionListPage', {
          tab: SpendingCardTransactionListPageTabs.SUCCESSFUL,
        })}
      />
      {/* Card Management Routes */}
      <CardManagementRoute
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:status(${cardStatusFormat})`}
        component={SpendingCardManagementListPage}
      />
      <CardManagementRoute
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:id/funds/:fundStatus`}
        component={SpendingCardManagementDetailsPage}
      />
      <CardManagementRoute
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:id/fund/:fundId${fundIdFormat}`}
        component={SpendingCardManagementFundDetailsPage}
      />
      <Redirect
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:id/funds/:fundId${fundIdFormat}`}
        to={`${SPENDING_CARD_MANAGEMENT_URL}/:id/fund/:fundId${fundIdFormat}`}
      />
      <Redirect
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:id/funds`}
        to={`${SPENDING_CARD_MANAGEMENT_URL}/:id/funds/${SpendingCardFundListTabs.OPEN}`}
      />
      <Redirect
        path={`${SPENDING_CARD_MANAGEMENT_URL}/:id`}
        to={`${SPENDING_CARD_MANAGEMENT_URL}/:id/funds/${SpendingCardFundListTabs.OPEN}`}
      />
      <Redirect
        path={SPENDING_CARD_MANAGEMENT_URL}
        to={`${SPENDING_CARD_MANAGEMENT_URL}/${PayCardStatuses.ACTIVE}`}
      />
      {/* Reconcile Transactions Routes */}
      <ReconcileTransactionsRoute
        path={SPENDING_CARD_RECONCILE_TRANSACTIONS_URL}
        component={SpendingCardReconcileTransactionsPage}
      />
      {/* Setup Route */}
      <ProtectedRoute
        path={SPENDING_CARD_SETUP_PAGE}
        component={SpendingCardSetupPage}
        navigationPermissions={['pay__card_management']}
        featureSwitches={['stripe_integration']}
      />
      Cardholder Routes
      <CardholderRoute
        path={brp('CardholderAccountPage')}
        component={CardholderAccountPage}
        exact
      />
      <CardholderRoute
        path={brp('CardholderAccountSetupPage')}
        component={CardholderAccountSetupPage}
        exact
      />
      <CardholderRoute
        path={brp('CardholderAccountEditPage')}
        component={CardholderAccountEditPage}
        exact
      />
      <CardholderRoute
        path={brp('CardholderAccountVerifyPage')}
        component={CardholderAccountVerifyPage}
        exact
      />
      <Route path='*' render={() => <Redirect to='/' />} />
    </Switch>
  )
}
